import React from 'react';
import Accordion from 'react-bootstrap/Accordion';
import Card from 'react-bootstrap/Card';


const faqs = [
  {
    question: "Can I create my own puzzles in Connections Unlimited?",
    answer: "Yes, you can! Use the custom puzzle feature to design your challenges by choosing words, themes, and difficulty levels. Share your puzzles with friends for extra fun."
  },
  {
    question: "Can I replay old puzzles?",
    answer: "Absolutely! The archive feature lets you access and replay previous puzzles. Its a great way to practice or revisit your favorite challenges."
  },
  {
    question: "Are there different difficulty levels?",
    answer: "Yes, the game offers Easy, Medium, and Hard modes, so you can pick the level that matches your experience and skills."
  },
  {
    question: "Can I play with friends?",
    answer: "Definitely! Multiplayer mode allows you to compete with friends or players worldwide. See who can solve puzzles the fastest for an exciting challenge."
  },
  {
    question: "What makes Connections Unlimited unique?",
    answer: "Its combination of fresh puzzles, customizable gameplay, and competitive multiplayer options sets it apart. The ability to create your puzzles adds a creative twist to the experience."
  },
  {
    question: "Does the game require an internet connection?",
    answer: "An internet connection is needed for multiplayer and some features, but solo puzzles can be played offline."
  },
  {
    question: "Are there in-game purchases?",
    answer: "While the game is free to play, optional purchases like hints and customization options are available. Ads may appear occasionally to support development."
  },
  {
    question: "How often is new content added?",
    answer: "New puzzles and updates are added regularly to keep the game fresh and exciting. Check back often for the latest features and challenges!"
  }
];

function FAQSection() {
  return (
    <div className="faq-section">
      <h2 className="faq-heading fw-900">FAQ’S</h2>
      <Accordion defaultActiveKey="0">
        {faqs.map((faq, index) => (
          <Card className="faq-card" key={index}>
            <Accordion.Item eventKey={index.toString()}>
              <Accordion.Header>{faq.question}</Accordion.Header>
              <Accordion.Body>
                {faq.answer}
              </Accordion.Body>
            </Accordion.Item>
          </Card>
        ))}
      </Accordion>
    </div>
  );
}

export default FAQSection;
