import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Dropdown, Card, Spinner, Col, Button, Alert } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import HomePageContent from './HomePageContent';
// import Footer from './Footer';
import StylishSpinner from './StylishSpinner';
import Time from './Timer';
import { useNavigate } from 'react-router-dom';
import ConfettiComponent from './ConfettiComponent';

const Game = () => {
    const [files, setFiles] = useState([]);
    const [selectedFile, setSelectedFile] = useState('');
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [selectedItems, setSelectedItems] = useState([]);
    const [mistakes, setMistakes] = useState(0);
    const [message, setMessage] = useState('');
    const [messageType, setMessageType] = useState('');
    const [completedGroups, setCompletedGroups] = useState([]);
    const [gameComplete, setGameComplete] = useState(false);
    const [shake, setShake] = useState(false);
    const [error, setError] = useState(null);

    // State to track play count and game file name

    // Fetch list of JSON files
    useEffect(() => {
        const fetchFiles = async () => {
            try {
                const urlParams = new URLSearchParams(window.location.search);
                const filename = urlParams.get('file');

                if (filename) {
                    const response = await axios.get(`https://www.connectionunlimited.net/gamedata/upload.php?file=${filename}`);
                    // setFiles([response.data]); 
                    // setSelectedFile(filename);
                    // fetchData(filename);
                    const fileData = response.data.find(file => file.filename === filename)?.data || [];
                    const shuffledData = shuffleArray(fileData.flatMap(group => group.members));
                    setData(shuffledData);
                    setLoading(false);
                    setSelectedFile(filename);
                    fetchData(filename);
                } else {
                    const response = await axios.get('https://www.connectionunlimited.net/gamedata/upload.php');
                    const sortedFiles = response.data.sort((a, b) => {
                        const dateA = new Date(a.filename.replace('.json', '').split('-').reverse().join('-'));
                        const dateB = new Date(b.filename.replace('.json', '').split('-').reverse().join('-'));
                        return dateB - dateA;
                    });
                    setFiles(sortedFiles);

                    if (sortedFiles.length > 0) {
                        const latestFile = sortedFiles[0].filename;
                        setSelectedFile(latestFile);
                        fetchData(latestFile);
                    }
                }
            } catch (err) {
                console.error('Error fetching files:', err);
                setError('Failed to fetch files.');
            }
        };


        fetchFiles();
    }, []);


    // Fetch data for the selected file
    const fetchData = async (filename) => {
        try {
            setLoading(true);
            const response = await axios.get(`https://www.connectionunlimited.net/gamedata/upload.php?file=${filename}`);
            const fileData = response.data.find(file => file.filename === filename)?.data || [];
            const shuffledData = shuffleArray(fileData.flatMap(group => group.members));
            setData(shuffledData);
            setLoading(false);
        } catch (error) {
            console.error('Error fetching data:', error);
            setLoading(false);
        }
    };

    // Handle file selection
    const handleSelect = (filename) => {
        setSelectedFile(filename);
        fetchData(filename);
        setSelectedItems([]);
        setCompletedGroups([]);
        setGameComplete(false);
        setMessage('');

    };

    // Shuffle array function
    const shuffleArray = (array) => {
        return array.sort(() => Math.random() - 0.5);
    };

    // Handle item click
    const handleItemClick = (item) => {
        setSelectedItems(prevItems => {
            const isAlreadySelected = prevItems.includes(item);
            if (isAlreadySelected) {
                return prevItems.filter(selected => selected !== item);
            } else {
                if (prevItems.length < 4) {
                    return [...prevItems, item];
                } else {
                    setMessage('You can only select up to 4 items.');
                    return prevItems;
                }
            }
        });
    };

    // Handle form submission
    const handleSubmit = () => {
        if (selectedItems.length !== 4) {
            setMessage('You need to select 4 items before submitting.');
            setMessageType('info'); // info message
            return;
        }

        // Convert selected items to a set for comparison
        const selectedItemsSet = new Set(selectedItems);
        // console.log('Selected Items:', selectedItems);
        // console.log('Selected Items Set:', Array.from(selectedItemsSet));

        // Fetch the data from the JSON file used in the game
        const fetchDataFromCurrentFile = async () => {
            try {
                const response = await axios.get(`https://www.connectionunlimited.net/gamedata/upload.php?file=${selectedFile}`);
                const fileData = response.data.find(file => file.filename === selectedFile)?.data || [];
                // console.log(fileData)
                // console.log("Response data:", response.data);
                // Find the group that matches the selected items
                const groupMatch = fileData.find(group => {
                    if (!group || !group.members) return false;

                    // Convert group members to a set for comparison
                    const groupMembersSet = new Set(group.members);
                    // console.log('Group Members:', group.members);
                    // console.log('Group Members Set:', Array.from(groupMembersSet));

                    // Compare sets
                    return groupMembersSet.size === selectedItemsSet.size &&
                        [...groupMembersSet].every(member => selectedItemsSet.has(member));
                });

                // console.log('Group Match:', groupMatch);

                if (groupMatch) {
                    // console.log('Matched Group:', groupMatch);
                    setMessage('Success! You correctly identified the group.');
                    setMessageType('success');
                    // setCompletedGroups(prev => [...prev, { groupName: groupMatch.group, members: groupMatch.members }]);
                    // Add the completed group with its color
                    setCompletedGroups(prev => [
                        ...prev,
                        {
                            groupName: groupMatch.group,
                            members: groupMatch.members,
                            color: groupMatch.color
                        }
                    ]);


                    

                    // Remove matched items from the data
                    setData(prevData => prevData.filter(member => !groupMatch.members.includes(member)));

                    setSelectedItems([]);
                    if (data.length <= 4) {
                        setGameComplete(true);
                        setMessage('Congratulations! You have completed all the groups.');
                        setMessageType('success');

                        // Save to localStorage
                        // Store game data under 'solvedGames'
                        const savedGames = JSON.parse(localStorage.getItem('solvedGames')) || {};
                        savedGames[selectedFile] = {
                            completedGroups,
                            date: new Date().toISOString(),
                        };
                        localStorage.setItem('solvedGames', JSON.stringify(savedGames));

                        // Store user preferences under 'userSettings'
                        const userSettings = JSON.parse(localStorage.getItem('userSettings')) || {};
                        userSettings.theme = 'dark'; // Example of user preference
                        localStorage.setItem('userSettings', JSON.stringify(userSettings));

                    }
                } else {
                    setMistakes(prevMistakes => {
                        if (prevMistakes < 3) {
                            setShake(true); // Trigger shake animation
                            setTimeout(() => setShake(false), 500); // Reset shake after animation
                            setMessage(`Incorrect guess, please try again. Mistakes remaining: ${3 - prevMistakes}`);
                            setMessageType('warning');
                        } else {
                            setMessage('Game over! You have made 4 mistakes.');
                            setMessageType('danger');
                            setGameComplete(true);
                        }
                        return prevMistakes + 1;
                    });
                }
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchDataFromCurrentFile();

        // save data from localStorage
        // incrementPlayCount(selectedFile);
    };



    const handleDeselect = () => {
        setSelectedItems([]);
    };

    const startGameAgain = () => {
        setSelectedItems([]);
        setMistakes(0);
        setMessage('');
        setCompletedGroups([]);
        setGameComplete(false);
        fetchData(selectedFile); // Reload data for the current file
    };
    // const getGroupClass = (index) => {
    //     const classes = ['bg-yellow', 'bg-green', 'bg-blue', 'bg-purple'];
    //     return classes[index % classes.length];
    // };

    const groupColors = ['bg-yellow', 'bg-green', 'bg-blue', 'bg-purple'];

    // const handleRefresh = () => {
    //     window.location.reload(); // Refreshes the page
    // };
    // Function to shuffle clues
    const handleShuffle = () => {
        setData(prevData => shuffleArray([...prevData])); // Shuffle the current data
        setMessage('The clues have been shuffled!');
        setMessageType('info');
    };


    const navigate = useNavigate();

    const handleButtonClick = (difficulty) => {
        const fileWithoutExtension = selectedFile.replace('.json', ''); // Remove .json from filename
        // Navigate to the new page with difficulty and date (file name without .json)
        // navigate(`/game-difficulty?option=${difficulty.toLowerCase()}&date=${fileWithoutExtension}`);
        navigate(`/game-difficulty?file=${fileWithoutExtension}-${difficulty.toLowerCase()}.json`);
    };

    const copyToClipboard = () => {
        const textToCopy = "connectionunlimited.net - 🟡🟡🟡🟡🔵🔵🔵🔵🔴🔴🔴🔴🟢🟢🟢🟢";
        navigator.clipboard.writeText(textToCopy)
            .then(() => {
                alert("Copied to Clipboard, you can now share the result in social media.");
            })
            .catch((err) => {
                console.error("Failed to copy text: ", err);
            });
    };

    // console.log(completedGroups)
    return (
        <>
            <div className="game-container">
                {/* <h1 className="mb-4 text-center">Connections Unlimited Game</h1> */}
                {error && <p>Error: {error}</p>}
                <div className='row p-0'>
                    <div className={`another-div ${completedGroups.length === 4 ? 'completed-hide' : ''}`}>
                        <h5 className='text-center mt-3 mb-0'>Create Correct Groups Four of Four!</h5>
                        <div className="text-start"><Time /></div>
                    </div>
                    {completedGroups.map((group, index) => (
                        <div
                            key={index}
                            style={{
                                backgroundColor: group.color || undefined,
                                padding: '25px',
                                marginBottom: '0px',
                            }}
                            className={`game-group ${!group.color ? groupColors[index] : ''}`}
                            
                        >
                            <h3 className="group-name fw-bold">{group.groupName}</h3>
                            <div className="group-members">
                                {group.members.join(', ')}
                            </div>
                        </div>
                    ))}

                </div>
                {completedGroups.length < 4 ? (
                    <>
                        {loading ? (
                            <StylishSpinner />
                        ) : (
                            <div id="grid" className="">
                                {data.map((member, index) => {
                                    const charCount = member.length; // Count the number of characters
                                    const charClass = `letter-${charCount}`;
                                    return (
                                        <div
                                            key={index}
                                            onClick={() => handleItemClick(member)}
                                            className={`word ${charClass} ${selectedItems.includes(member) ? 'selected' : ''}
                                    ${shake && selectedItems.includes(member) ? 'shake' : ''}`}
                                        >
                                            {member}
                                        </div>
                                    );
                                })}
                            </div>

                        )}

                        <div className="mistakes" id="mistakes">
                            Mistakes remaining:
                            {Array(4) // Total 4 dots for mistakes
                                .fill()
                                .map((_, index) => (
                                    <span
                                        key={index}
                                        className={`mx-2 mistake-dot ${index < mistakes ? 'used' : ''}`}
                                    ></span>
                                ))}
                        </div>

                        <div className="d-flex justify-content-between mt-4">
                            <Button onClick={handleSubmit} className="checkConnections mx-1">Submit</Button>
                            <Button onClick={handleDeselect} className="deselectAll mx-1">Deselect All</Button>
                            <Button onClick={handleShuffle} className="shuffle mx-1">Shuffle</Button>
                        </div>

                        {message && <Alert variant={messageType} className="text-center mt-3">{message}</Alert>}

                        {gameComplete && (
                            <div className="text-center mt-4">
                                <Button onClick={startGameAgain} variant="primary">Start Game Again</Button>
                            </div>
                        )}

                    </>
                ) : (
                    <div className="congratulations">
                        {/* <h2>Congratulations!</h2> */}
                        {/* <p>You have found all groups!</p> */}
                        <ConfettiComponent />
                        <div className="share">
                            <div className="share-row">
                                <span style={{ backgroundColor: "rgb(114, 158, 235)" }}></span>
                                <span style={{ backgroundColor: "rgb(114, 158, 235)" }}></span>
                                <span style={{ backgroundColor: "rgb(114, 158, 235)" }}></span>
                                <span style={{ backgroundColor: "rgb(114, 158, 235)" }}></span>
                            </div>
                            <div className="share-row">
                                <span style={{ backgroundColor: "rgb(251, 212, 0)" }}></span>
                                <span style={{ backgroundColor: "rgb(251, 212, 0)" }}></span>
                                <span style={{ backgroundColor: "rgb(251, 212, 0)" }}></span>
                                <span style={{ backgroundColor: "rgb(251, 212, 0)" }}></span>
                            </div>
                            <div className="share-row">
                                <span style={{ backgroundColor: "rgb(188, 112, 196)" }}></span>
                                <span style={{ backgroundColor: "rgb(188, 112, 196)" }}></span>
                                <span style={{ backgroundColor: "rgb(188, 112, 196)" }}></span>
                                <span style={{ backgroundColor: "rgb(188, 112, 196)" }}></span>
                            </div>
                            <div className="share-row">
                                <span style={{ backgroundColor: "rgb(181, 227, 82)" }}></span>
                                <span style={{ backgroundColor: "rgb(181, 227, 82)" }}></span>
                                <span style={{ backgroundColor: "rgb(181, 227, 82)" }}></span>
                                <span style={{ backgroundColor: "rgb(181, 227, 82)" }}></span>
                            </div>
                        </div>
                        <Button className='btn checkConnections bg-black' onClick={copyToClipboard}>
                            Share Result
                        </Button>

                        <div className="text-center mt-4">
                            <a href='/archive' className='btn shuffle p-3'>Start New Game</a>
                        </div>
                    </div>
                )}
                <div className="text-center mb-2 mt-4">
                    <Button className='btn btn-difficulty mx-1' onClick={() => handleButtonClick('Easy')}>Easy</Button>
                    <Button className='btn btn-difficulty mx-1' onClick={() => handleButtonClick('Medium')}>Medium</Button>
                    <Button className='btn btn-difficulty mx-1' onClick={() => handleButtonClick('Hard')}>Hard</Button>
                </div>
                <div className='row text-center my-4'>
                    {/* <p className='text-center'>--Select an archive date--</p> */}
                    <Dropdown>
                        <Dropdown.Toggle className='archive-btn' id="dropdown-basic">
                            {/* {selectedFile ? selectedFile.replace('.json', '') : '--Select an archive date--'} */}
                            --Select an archive date--
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                            {files
                                .sort((a, b) => {
                                    const dateA = new Date(a.filename.replace('.json', '').split('-').reverse().join('-'));
                                    const dateB = new Date(b.filename.replace('.json', '').split('-').reverse().join('-'));
                                    return dateB - dateA;
                                })
                                .map(file => (
                                    <Dropdown.Item key={file.filename} onClick={() => handleSelect(file.filename)}>
                                        {file.filename.replace('.json', '')}
                                    </Dropdown.Item>
                                ))}
                        </Dropdown.Menu>
                    </Dropdown>


                </div>

            </div>

                                
            <HomePageContent />

        </>
    );
};

export default Game;
