import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import StylishSpinner from './StylishSpinner';
import { Helmet } from 'react-helmet';

const BlogDetail = () => {
  const { slug } = useParams();
  const [post, setPost] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [toc, setToc] = useState([]); // For the table of contents
  const [isTocVisible, setIsTocVisible] = useState(false); // Toggle visibility

  useEffect(() => {
    axios.get(`https://www.connectionunlimited.net/gamedata/api/blog.php?slug=${slug}`)
      .then(response => {
        const postData = response.data.data;
        setPost(postData);
        setLoading(false);
        generateTOC(postData.description); // Generate TOC based on post content
      })
      .catch(error => {
        setError('Error fetching blog post');
        setLoading(false);
      });
  }, [slug]);

  // Function to generate the table of contents
  const generateTOC = (htmlContent) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlContent, 'text/html');
    const headings = doc.querySelectorAll('h2, h3'); // Adjust this based on which headings you want in TOC
    const tocItems = [];

    headings.forEach((heading, index) => {
      const id = `heading-${index}`;
      heading.id = id; // Set the ID for the heading
      tocItems.push({
        id,
        text: heading.textContent,
        level: heading.tagName.toLowerCase(), // to handle h2, h3, etc.
      });
    });

    setToc(tocItems);

    // Update the post description with the modified HTML content including the IDs.
    setPost((prevPost) => ({
      ...prevPost,
      description: doc.body.innerHTML,
    }));
  };


  // Toggle the visibility of TOC
  const handleTocToggle = () => {
    setIsTocVisible(prevState => !prevState);
  };

  // Generate JSON-LD schema based on post data
  const generateSchema = () => {
    if (!post) return null;

    return {
      "@context": "https://schema.org",
      "@type": "BlogPosting",
      "mainEntityOfPage": {
        "@type": "WebPage",
        "@id": `https://connectionunlimited.net/blog/${slug}`
      },
      "headline": post.title,
      "image": `https://connectionunlimited.net/gamedata/uploads/${post.image}`,
      "datePublished": post.date,
      "dateModified": post.date,
      "author": {
        "@type": "Person",
        "name": post.author
      },
      "publisher": {
        "@type": "Organization",
        "name": "Connections Unlimited",
        "logo": {
          "@type": "ImageObject",
          "url": "https://connectionunlimited.net/logo.png"
        }
      },
      "description": post.meta_description || 'Blog post description',
      "articleBody": post.meta_description
    };
  };

  if (loading) return <div className="spinner-container">
    <StylishSpinner />
  </div>;
  if (error) return <div>{error}</div>;

  return (
    <div className="container blog-detail">
      {/* Helmet to manage document head */}
      <Helmet>
        <title>{post?.title ? post.title : 'Loading...'}</title>
        <meta name="description" content={post?.meta_description || 'Blog post description'} />
        <meta property="og:title" content={post?.meta_title || 'Loading...'} />
        <meta property="og:description" content={post?.meta_description || 'Blog post description'} />
        <meta property="og:image" content={`https://connectionunlimited.net/gamedata/uploads/${post?.image}`} />
        <meta property="og:type" content="article" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={post?.meta_title || 'Loading...'} />
        <meta name="twitter:image" content={`https://connectionunlimited.net/gamedata/uploads/${post?.image}`} />
        <meta name="google-site-verification" content="rjScEOjQsp2P6EZKTLw8iYSh6F0Dqp3q5fEpeBfqCQk" />
        <meta name="author" content={post?.author} />
        <link rel="canonical" href={`https://connectionunlimited.net/${post.slug}`} />
        <script type="application/ld+json">
          {JSON.stringify(generateSchema())}
        </script>
      </Helmet>
      <div className="row">
        <div className='col-md-9'>
          <h1 className="pt-4 px-4">{post.title}</h1>
        </div>
        <div className="col-md-3">
          <div className="author">
            <img
              className="author__avatar"
              src={`/author.png`}
              alt={`${post.author} profile`}
            />
            <div className="author__details">
              <div>written by <br /><a href="/" rel="author">{post.author}</a></div>
              <time title={post.date}>{post.date}</time>
            </div>
          </div>
        </div>
        <div className="col-md-12">
          <img
            src={`https://www.connectionunlimited.net/gamedata/uploads/${post.image}`}
            alt={post.title}
            width="800"
            height="600"
            className="img_banner img-fluid"
            priority
          />
          <div className="toc">
            <h4 onClick={handleTocToggle} className="toc-header">
              <FontAwesomeIcon icon={isTocVisible ? faChevronUp : faChevronDown} />
              {isTocVisible ? ' Hide Table of Contents' : ' Table of Contents'}
            </h4>
            {isTocVisible && (
              <ul className="toc-list">
                {toc.map(item => (
                  <li key={item.id} className={`toc-item ${item.level}`}>
                    <a href={`#${item.id}`}>{item.text}</a>
                  </li>
                ))}
              </ul>
            )}
          </div>
          <div className="blog-content">
            <div dangerouslySetInnerHTML={{ __html: post.description }} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default BlogDetail;
