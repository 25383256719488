import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';

function Footer() {
  return (
    <footer className="bg-light text-dark py-4">
      <Container>
        <Row>
          <Col className="text-center">
            <div className="d-flex justify-content-center align-items-center gap-2"> {/* Flex container for image and h1 */}
              <a href="/" className="d-flex align-items-center text-decoration-none text-dark">
                <img className="my-2" src="/fav.png" width={40} height={40} alt="logo" />
                <h3 className='logo-text mb-0 ms-2 d-none d-md-block'>Connections Unlimited</h3> {/* Adding margin-left to the heading */}
              </a>
            </div>
            <ul className="list-unstyled d-flex justify-content-center">
            <li className="mx-3">
                <a href="/blog" className="text-decoration-none">Blog</a>
              </li>
            <li className="mx-3">
                <a href="/" className="text-decoration-none">Game</a>
              </li>
              <li className="mx-3">
                <a href="/about-us" className="text-decoration-none">About Us</a>
              </li>
              <li className="mx-3">
                <a href="/contact-us" className="text-decoration-none">Contact Us</a>
              </li>
              <li className="mx-3">
                <a href="/privacy-policy" className="text-decoration-none">Privacy Policy</a>
              </li>
            </ul>
            <p className="mt-3 mb-0">&copy; {new Date().getFullYear()} Connections Unlimited. All rights reserved.</p>
          </Col>
        </Row>
      </Container>
    </footer>
  );
}

export default Footer;
