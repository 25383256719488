import React from 'react'
import { Container, Row, Col, Card, Button, Accordion } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import {
  FaInfinity,
  FaGamepad,
  FaUsers,
  FaCog,
  FaPaintBrush,
  FaPlusCircle
} from 'react-icons/fa';
import FAQSection from './FAQSection';

function HomePageContent() {

  return (
    <>
      <div className='key-features-section py-5' id='website'>
        <div className='container'>
          <h1 className="mb-4 text-center section-title">Connections Unlimited Game</h1>
          <p className='text-center'>

            Play NYT Connections Unlimited Game , the exhilarating, Wordle-inspired upgrade of the classic NYT Connections Game. Enjoy limitless fun as you enhance your vocabulary and conquer ever-evolving word challenges. With engaging gameplay and regular updates, NYT Connections Unlimited Game promises endless entertainment and mental stimulation for players of all ages. Jump in now and discover the ultimate word game thrill!
          </p>

          <h2 className='mt-4 text-center fw-900'>Introduction to Connections Unlimited </h2>
          <p className='text-center'>
          <b>Connections Unlimited</b> is an engaging word puzzle game that challenges your mind and creativity. The game revolves around identifying common themes among words and linking them together. With its fresh puzzles and dynamic gameplay, it provides endless entertainment for players of all skill levels.
          </p>
          <p className="text-center">
          What makes the game even better is its versatility. Whether you&apos;re exploring the <b>Easy</b>, <b>Medium</b>, or <b>Hard</b> levels, each puzzle offers a unique challenge tailored to your abilities. The archive feature allows you to revisit and replay older puzzles, making it easy to track your progress or enjoy your favorite games again.
          </p>
          <p className="text-center">
          For those who enjoy competing, the multiplayer mode adds an extra layer of excitement. Challenge your friends or other players to see who can find the connections faster. With its variety of features, <b>Connections Unlimited</b> is a puzzle game designed for everyone to enjoy.
          </p>
        </div>
      </div>
      <div className="create-div-home">
        <div className="container text-center">
          <h1 className="section-title">Create Your Own Custom Connections Game</h1>
          <p className="section-subtitle mb-4">
          With <b>Connections Unlimited</b>, you have the exciting feature to create your connection puzzles and play them instantly. This allows you to design challenges that are uniquely yours, selecting words, themes, and difficulty levels that match your style and creativity
          </p>
          <p className="section-subtitle mb-4">
          Whether you want to craft a puzzle for personal enjoyment or to share with friends, this feature provides endless possibilities. You can create simple puzzles for a casual game or complex ones to test your skills. Once designed, your puzzles are ready to play, offering a truly personalized experience.
          </p>
          <p className="section-subtitle mb-4">
          Unleash your imagination, design your connection puzzles, and enjoy the satisfaction of solving something entirely on your own.
          </p>
          {/* <a href="/create" className='btn btn-primary text-inline'> <FaPlusCircle size={15} /> Create Puzzle </a> */}
         <a href="/create" className='btn creat-btn-home'>
        <FaPlusCircle size={15} className="me-2" /><span className='align-middle'>Create Puzzle</span> 
        </a>
        </div>
      </div>
      <div className="container my-5">
        <h2 className="text-center mb-4 fw-900">How to Play Connections Unlimited Game</h2>
        <p className='text-center'>Playing Connections Unlimited is simple yet deeply rewarding. Here&apos;s a step-by-step guide to get started and enjoy the game:</p>
        <div className="row">
          <div className="col-md-12 mb-4">
            <Card className="how-to-play-card">
              <Card.Body>
                <Card.Title>Step 1: Understand the Words</Card.Title>
                <Card.Text>
                Begin by carefully reading the list of words provided in the puzzle. Each word holds clues to potential connections.
                </Card.Text>
                <h6 className='fw-bold'>Quick Tip:</h6>
                <p>If you&apos;re unsure about a word, look it up to understand its meaning better. This will make it easier to spot connections.</p>
               <div className="d-flex text-center justify-content-center">
               <img src="step1.png" width={600} height={500} alt="Understand the Words" />
               </div>
              </Card.Body>
            </Card>
          </div>
          <div className="col-md-12 mb-4">
            <Card className="how-to-play-card">
              <Card.Body>
                <Card.Title>Step 2: Find the Common Theme</Card.Title>
                <Card.Text>
                Look for a common theme that links certain words together. <br/>
                Ask yourself:
                <ul>
                  <li>Are the words part of the same category, like emotions, tools, or animals?</li>
                  <li>Do they share a specific concept, such as speed, nature, or seasons?</li>
                </ul>
                </Card.Text>
                <p><b>Example:</b> Words like &quot;Bolt,&quot; &quot;Dash,&quot; &quot;Fly,&quot; and &quot;Zoom&quot; might all relate to speed.</p>
                <div className="d-flex text-center justify-content-center">
               <img src="step2.png" width={600} height={500} alt="Find the Common Theme" />
               </div>
              </Card.Body>
            </Card>
          </div>
          <div className="col-md-12 mb-4">
            <Card className="how-to-play-card">
              <Card.Body>
                <Card.Title>Step 3: Select and Submit Your Answer</Card.Title>
                <Card.Text>
                Once you’ve identified four words with a shared theme:

                <ul>
                  <li>Select the words in the game interface.</li>
                  <li>Confirm your selection by clicking the "Submit" button.</li>
                </ul>
                </Card.Text>
                <p className='fw-bold'>What if You're Wrong?</p>
                <p>Don’t worry! Wrong answers help narrow down your options, so revisit the words, think through the possibilities, and try again.</p>
                <h6>Extra Tips for Success</h6>
                <ul>
                  <li><b>Stay Focused:</b> Write down possible themes to stay organized.</li>
                  <li><b>Be Creative:</b> Not all connections are obvious, so think outside the box.</li>
                  <li><b>Learn and Improve:</b> Each puzzle you solve sharpens your skills, making you faster and more confident.</li>
                </ul>
                <div className="d-flex text-center justify-content-center">
               <img src="step3.png" width={600} height={500} alt="Select and Submit Your Answer" />
               </div>
              </Card.Body>
            </Card>
          </div>
        </div>
        {/* <div className="row mt-4">
          <div className="col-md-4">
            <Card className="how-to-play-card">
              <Card.Body>
                <Card.Title>Step 4: Review and Adjust</Card.Title>
                <Card.Text>
                  Before you submit, review all your connections. If something seems off, make adjustments to ensure your connections are accurate.
                </Card.Text>
              </Card.Body>
            </Card>
          </div>
          <div className="col-md-4">
            <Card className="how-to-play-card">
              <Card.Body>
                <Card.Title>Step 5: Submit Your Answers</Card.Title>
                <Card.Text>
                  Once you're satisfied with your connections, submit them to see your score. The game will evaluate your connections based on accuracy and creativity.
                </Card.Text>
              </Card.Body>
            </Card>
          </div>
          <div className="col-md-4">
            <Card className="how-to-play-card">
              <Card.Body>
                <Card.Title>Step 6: Level Up</Card.Title>
                <Card.Text>
                  As you advance, puzzles will become more challenging. Keep practicing and honing your skills to tackle higher levels!
                </Card.Text>
              </Card.Body>
            </Card>
          </div>
        </div> */}

      </div>

      
      <div className="key-features-section py-5" id='webpage'>
        <Container>
          {/* Hero Section */}
          <div className="text-center mb-5">
            <h1 className="section-title">Key Features of Connections Unlimited</h1>
            <p className="section-subtitle">
            <b>Connections Unlimited</b> boasts several unique features that make it a standout game:
            </p>
          </div>

          {/* Features Grid */}
          <Row className="g-5">
            <Col md={6} lg={4}>
              <Card className="feature-card h-100">
                <div className="icon-wrapper">
                  <FaInfinity className="feature-icon" />
                </div>
                <Card.Body>
                  <Card.Title>Unlimited Puzzles</Card.Title>
                  <Card.Text>
                  Enjoy an endless variety of puzzles in Connections Unlimited. Each session offers fresh words and unique themes, ensuring you always have something new to challenge your mind and keep the game exciting.
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>

            <Col md={6} lg={4}>
              <Card className="feature-card h-100">
                <div className="icon-wrapper">
                  <FaGamepad className="feature-icon" />
                </div>
                <Card.Body>
                  <Card.Title>Flexible Difficulty Levels</Card.Title>
                  <Card.Text>
                  Choose between Easy, Medium, and Hard levels to match your skill. Whether you&apos;re a casual player or a puzzle enthusiast, you&apos;ll find a level that suits you. Adjust the difficulty anytime for a tailored experience.
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>

            <Col md={6} lg={4}>
              <Card className="feature-card h-100">
                <div className="icon-wrapper">
                  <FaUsers className="feature-icon" />
                </div>
                <Card.Body>
                  <Card.Title>Multiplayer Mode</Card.Title>
                  <Card.Text>
                  Compete with others in an exciting multiplayer mode. Test your skills against friends or players worldwide to see who can solve puzzles the fastest. Multiplayer adds a fun and competitive edge to the game.
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>

            <Col md={6} lg={4}>
              <Card className="feature-card h-100">
                <div className="icon-wrapper">
                  <FaCog className="feature-icon" />
                </div>
                <Card.Body>
                  <Card.Title>Archive Feature</Card.Title>
                  <Card.Text>
                  Revisit your favorite puzzles anytime with the archive feature. Perfect for practicing or replaying challenges, the archive lets you explore past games and refine your strategies.
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>

            <Col md={6} lg={4}>
              <Card className="feature-card h-100">
                <div className="icon-wrapper">
                  <FaPaintBrush className="feature-icon" />
                </div>
                <Card.Body>
                  <Card.Title>Custom Puzzle Creation</Card.Title>
                  <Card.Text>
                  Design your puzzles with custom words and themes. Choose the difficulty and share your creations with others. This feature ensures every game feels unique and tailored to your preferences.
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
            <Col md={6} lg={4}>
              <Card className="feature-card h-100">
                <div className="icon-wrapper">
                  <FaPaintBrush className="feature-icon" />
                </div>
                <Card.Body>
                  <Card.Title>Simple and Engaging Design</Card.Title>
                  <Card.Text>
                  With vibrant visuals and a user-friendly interface, Connections Unlimited is easy to navigate and fun to play. Its smooth animations and clear layout ensure an enjoyable experience for all.
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
          </Row>

          {/* Conclusion Section */}
          <div className="conclusion-section text-center mt-5">
            <h2 className='fw-900'>Conclusion</h2>
            <p>
            <b>Connections Unlimited</b> is the ultimate word puzzle game for anyone who loves solving challenges and exploring creative connections. It&apos;s designed to be fun, engaging, and adaptable to all skill levels. Whether playing solo or with friends, the game offers endless entertainment.
            </p>
            <p>
            The ability to create custom puzzles, revisit older games through the archive, and compete with others makes this game stand out. Its vibrant design and smooth gameplay enhance the experience, ensuring every session is enjoyable.
            </p>
            <p>
            With options to choose your difficulty, tackle new puzzles daily, and even design your own, <b>Connections Unlimited</b> is more than a game. It's a way to sharpen your mind while having fun. Start your journey today and uncover how many creative connections you can make.
            </p>
          </div>
        </Container>
      </div>

      <FAQSection />

    </>

  )
}

export default HomePageContent