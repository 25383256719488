import React, { useState, useEffect } from 'react';
import { Table, FormControl, InputGroup, Button } from 'react-bootstrap';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';

const Archive = () => {
    const navigate = useNavigate();

    // This function will handle the click on an archive file
    const handleFileClick = (fileName) => {
        navigate(`/game?file=${fileName}`);
    };

    const [files, setFiles] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const [filteredFiles, setFilteredFiles] = useState([]);
    const [completedGames, setCompletedGames] = useState({});

    // Pagination state
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 10; // Number of items to display per page

    // Fetch the list of files from the server
    useEffect(() => {
        const fetchFiles = async () => {
            try {
                const response = await axios.get('https://connectionsgames.net/gamedata/upload.php');
                const files = response.data;

                // Sort files by date in descending order (latest first)
                const sortedFiles = files.sort((a, b) => {
                    const dateA = parseDateFromFilename(a.filename);
                    const dateB = parseDateFromFilename(b.filename);
                    return dateB - dateA;  // Sort in descending order
                });

                setFiles(sortedFiles);
                setFilteredFiles(sortedFiles); // Initially show all files

                // Retrieve completed games from localStorage
                const savedGames = JSON.parse(localStorage.getItem('solvedGames')) || {};
                setCompletedGames(savedGames);
            } catch (error) {
                console.error('Error fetching files:', error);
            }
        };

        fetchFiles();
    }, []);

    // Helper function to parse date from filename (DD-MM-YYYY format)
    const parseDateFromFilename = (filename) => {
        const datePart = filename.replace('.json', '').split('-');
        const day = parseInt(datePart[0], 10);
        const month = parseInt(datePart[1], 10) - 1;  // Months are 0-based in JavaScript
        const year = parseInt(datePart[2], 10);
        
        return new Date(year, month, day);  // Create a new Date object
    };

    // Helper function to format date as 'Month DD, YYYY'
    const formatDate = (filename) => {
        const date = parseDateFromFilename(filename);
        const options = { year: 'numeric', month: 'long', day: 'numeric' };
        return date.toLocaleDateString('en-US', options); // Format the date as "Month DD, YYYY"
    };

    // Filter files based on the search query
    const handleSearchChange = (e) => {
        setSearchQuery(e.target.value);
        const filtered = files.filter(file =>
            file.filename.toLowerCase().includes(e.target.value.toLowerCase())
        );
        setFilteredFiles(filtered);
        setCurrentPage(1); // Reset to first page when search query changes
    };

    // Calculate the files to show based on the current page
    const currentFiles = filteredFiles.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);

    // Handle previous page
    const handlePreviousPage = () => {
        if (currentPage > 1) {
            setCurrentPage(prev => prev - 1);
        }
    };

    // Handle next page
    const handleNextPage = () => {
        if (currentPage < Math.ceil(filteredFiles.length / itemsPerPage)) {
            setCurrentPage(prev => prev + 1);
        }
    };

    // Check if a file is completed (based on the date stored in localStorage)
    const checkIfCompleted = (fileName) => {
        const savedGames = completedGames;
        const dateKey = `${fileName}`;
        return savedGames[dateKey] ? true : false;
    };

    return (
        <div className="archive-container game-container">
            <Helmet>
            <title>Archive | NYT Connections Style Game </title>
            <link rel="canonical" href="https://www.connectionunlimited.net/archive" />
            </Helmet>
            {/* <h1 className="text-center mb-4">Archive</h1> */}
            <div className="search-bar my-4">
                <InputGroup className="mb-3">
                    <FormControl
                        placeholder="Search game files"
                        value={searchQuery}
                        onChange={handleSearchChange}
                    />
                    <Button variant="outline-secondary">Search</Button>
                </InputGroup>
            </div>

            {/* Game Files Table */}
            <table className='archive-table' style={{ width: '100%', borderCollapse: 'collapse' }}>
                <thead>
                    <tr>
                        <th></th>
                        <th>Name</th>
                        <th>Date</th>
                    </tr>
                </thead>
                <tbody className='archive-tbody'>
                    {currentFiles.map((file, index) => {
                        const connectionsNumber = filteredFiles.length - ((currentPage - 1) * itemsPerPage + index);  // Correct numbering
                        const isCompleted = checkIfCompleted(file.filename);  // Check if this file is marked as completed
                        return (
                            <tr
                                key={file.filename}
                                onClick={() => handleFileClick(file.filename)}
                                className={isCompleted ? 'solved' : ''} // Add class for completed rows
                            >
                                <td>
                                    <svg viewBox="0 0 14 14" focusable="false" className="chakra-icon css-1iww5ue">
                                        <g fill="currentColor">
                                            <polygon points="5.5 11.9993304 14 3.49933039 12.5 2 5.5 8.99933039 1.5 4.9968652 0 6.49933039"></polygon>
                                        </g>
                                    </svg>   
                                </td>
                                <td><b> {`Connections #${connectionsNumber}`}</b></td>
                                <td>{formatDate(file.filename)}</td>
                            </tr>
                        );
                    })}
                </tbody>
            </table>

            {/* Pagination Controls */}
            <div className="pagination-controls text-center my-4">
                <Button onClick={handlePreviousPage} disabled={currentPage === 1} className="mx-2 btn-dark rounded-pill">Previous</Button>
                <Button onClick={handleNextPage} disabled={currentPage === Math.ceil(filteredFiles.length / itemsPerPage)} className="mx-2 btn-dark rounded-pill">Next</Button>
            </div>
        </div>
    );
};

export default Archive;
