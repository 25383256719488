import React, { useEffect, useState } from 'react';
import confetti from 'canvas-confetti'; // Install using: npm install canvas-confetti

const ConfettiComponent = () => {
    const [confettiPlayed, setConfettiPlayed] = useState(false);

    useEffect(() => {
        if (!confettiPlayed) {
            triggerConfetti();
            setConfettiPlayed(true); // Prevent confetti from playing again
        }
    }, [confettiPlayed]);

    const triggerConfetti = () => {
        confetti({
            particleCount: 150,
            spread: 70,
            origin: { y: 0.6 },
        });
    };

    return (
        <div>
            <h2>Congratulations! 🎉</h2>
            <p>You have found all groups!</p>
        </div>
    );
};

export default ConfettiComponent;
